import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _60303945 = () => interopDefault(import('../pages/thank-you/index.vue' /* webpackChunkName: "pages/thank-you/index" */))
const _25df5dbd = () => interopDefault(import('../pages/embed/_project/index.vue' /* webpackChunkName: "pages/embed/_project/index" */))
const _0dba2cfd = () => interopDefault(import('../pages/project/_project/index.vue' /* webpackChunkName: "pages/project/_project/index" */))
const _2eae85d6 = () => interopDefault(import('../pages/embed/_project/space/_space.vue' /* webpackChunkName: "pages/embed/_project/space/_space" */))
const _632245d5 = () => interopDefault(import('../pages/project/_project/space/_space.vue' /* webpackChunkName: "pages/project/_project/space/_space" */))
const _3a83b678 = () => interopDefault(import('../pages/project/_project/_slug/index.vue' /* webpackChunkName: "pages/project/_project/_slug/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f6ee20da = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))
const _04ccb8ed = () => interopDefault(import('../pages/_slug/_static_page_slug/index.vue' /* webpackChunkName: "pages/_slug/_static_page_slug/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/thank-you",
    component: _60303945,
    name: "thank-you"
  }, {
    path: "/embed/:project",
    component: _25df5dbd,
    name: "embed-project"
  }, {
    path: "/project/:project",
    component: _0dba2cfd,
    name: "project-project"
  }, {
    path: "/embed/:project?/space/:space?",
    component: _2eae85d6,
    name: "embed-project-space-space"
  }, {
    path: "/project/:project?/space/:space?",
    component: _632245d5,
    name: "project-project-space-space"
  }, {
    path: "/project/:project?/:slug",
    component: _3a83b678,
    name: "project-project-slug"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/:slug",
    component: _f6ee20da,
    name: "slug"
  }, {
    path: "/:slug/:static_page_slug",
    component: _04ccb8ed,
    name: "slug-static_page_slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
